import {
  Text,
  TextFontWeight,
  TextSize,
} from '@dist-property-frontend/toolkit';
import styled from 'styled-components';

type SRPCardTitlePriceProps = {
  soldSection?: boolean;
  soldPrice?: string;
  titlePrice: string;
  limitedContent?: boolean;
};

const StyledAskingPrice = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => theme.fontSize.S14};
  font-weight: ${({ theme }) => theme.fontWeight.REGULAR};
  color: ${({ theme }) => theme.color.GREY_DARK};
  border-left: 1px solid ${({ theme }) => theme.color.GREY_LIGHTER};
  padding-left: ${({ theme }) => theme.spacing.S8};
  margin-left: ${({ theme }) => theme.spacing.S8};
`;

const SRPCardTitlePrice = ({
  soldSection,
  soldPrice,
  titlePrice,
  limitedContent,
}: SRPCardTitlePriceProps) => (
  <Text
    as="p"
    size={TextSize.M16}
    fontWeight={TextFontWeight.SEMIBOLD}
    style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {soldSection ? (
      <>
        Sold: {soldPrice}{' '}
        {!limitedContent && (
          <StyledAskingPrice>Asking: {titlePrice}</StyledAskingPrice>
        )}
      </>
    ) : (
      titlePrice
    )}
  </Text>
);

export default SRPCardTitlePrice;
